import React, { useContext } from 'react'
import Form, { Input } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const EditForm = ({ _id }) => {

	const formDef = {
		_id,
		callSet: _id ? "ops/recipe/update" : "ops/recipe/create",
		callGet: _id && "ops/recipe/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Nome" name="label" />
		</Form>
	)
}

const RecipeEdit = ({ _id, open, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh() : handleRefresh()
	}

	const title = (_id ? 'Modifica' : 'Crea') + ' ricetta'
	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}
export default RecipeEdit
