import React, { useMemo, useState } from 'react';

import Form, { Input, Select, InputDate } from '#Form'

export default function LoadPlanForm({ planPath, machines, lastDate, setLastDate }) {
	const [ data, setData ] = useState({})

	const machineOpts = useMemo(() => {
		return machines.map(machine => ({
			value: machine,
		}))
	}, [ machines ])

	const formDef = {
		// _id,
		callSet: "plan/enqueue",
		data,
		setData: (data, { isNew }) => {
			data.date && setLastDate(data.date)
			setData(isNew ? {
				path: planPath.join('/'),
				machine: machineOpts[0].value,
				date: lastDate ? lastDate : '',
			} : data)
		},
	}

	return (
		<Form {...formDef}>
			<Input label="Programma" name="path" required disabled />
			<Select label="Macchina" name="machine" options={machineOpts} emptyLabel="--- seleziona una macchina ---" required />
			<InputDate label="Data di lavorazione" name="date" required />
		</Form>
	)
}
