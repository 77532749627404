import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchText } from '#Search'
import DataList from '#DataList'
import Fab from '#Fab'

import RecipeEdit from './RecipeEdit'

import {
	faPlus,
	faEdit,
	faTrash,
	faDiagramSubtask,
	faTableCellsRowLock,
} from '@fortawesome/pro-regular-svg-icons'


const tableDef = [
	{ label:"Nome", field:'label' },
	{ label:"SAP", field:'sap', type:'boolean', trueIcon:faTableCellsRowLock, falseIcon:null, tooltip:row => row.sap || '-' },
]

export default function RecipeList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ editItem, setEditItem ] = useState()
	const [ filterString, setFilterString ] = useState('')
	
	const handleDelete = ({ _id, label }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare la sequenza "' + label + '"?',
			onConfirm: () => api.call('ops/recipe/delete', { _id })
				.then(handleRefresh),
		})

	const handleRefresh = () =>
		api.call('ops/recipe/list', { filterString }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ filterString ])

	const handleEditClose = async () =>
		setEditItem(null)

	const getRow = _id => api.call('ops/recipe/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica nome',
			onClick: setEditItem,
			show: row => !row.sap,
		},
		{
			icon: faDiagramSubtask,
			label: 'Gestione fasi',
			linkTo: row => '/ops/recipes/' + row._id + '/phases',
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
			show: row => !row.sap,
		},
	]

	return (
		<Tpl title="Sequenze di lavorazione">
			<RecipeEdit {...editItem} open={Boolean(editItem)} handleRefresh={handleRefresh} handleClose={handleEditClose} />
			<Search>
				<SearchText label="Cerca" name="src-text" onChange={setFilterString} />
			</Search>
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
			<Fab label="Crea sequenza" icon={faPlus} onClick={() => setEditItem({ _id:null })} />
		</Tpl>
	)
}
