import React, { useEffect, useState, useContext } from 'react'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import Dialog from '#Dialog'
import { faClock, faPlus, faEdit, faTrash } from '@fortawesome/pro-regular-svg-icons'
import EditForm from './Edit'

const tabDef = [
	{ label:"Nome", field:"label" },
]

const EditMachine = ({ _id, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		handleRefresh()
		handleRowRefresh?.()
	}

	return (
		<Dialog
			handleClose={triggerClose}
			open={Boolean(_id) || _id === null}
			title={ (_id ? "Modifica" : "Creazione") + " macchina" }
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}

export default function MachineList() {
	const [ ids, setIds ] = useState([])
	const [ editEvent, setEditEvent ] = useState(null)
	const api = useContext(APICtx)

	const handleDelete = ({ _id, label }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler rimuovere la macchina "' + label + '"?',
			onConfirm: () => api.call('machine/del', { _id }).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('machine/list').then(setIds)

	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => ({ _id, label:_id })

	const defBtns = [
		{
			icon: faClock,
			label: 'Programmazione giornate',
			linkTo: row => '/machine/plans/' + row._id,
		},
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: setEditEvent,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	]

	return (
		<Tpl title="Macchine industria 4.0">
			<EditMachine handleRefresh={handleRefresh} handleClose={() => setEditEvent(null)} {...editEvent} />
			<DataList def={tabDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
			<Fab label="Aggiungi macchina" icon={faPlus} onClick={() => setEditEvent({ _id:null })} />
		</Tpl>
	)
}
