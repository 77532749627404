import React, { useEffect, useState, useContext } from 'react'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { ListItemButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Dialog from '#Dialog'
import Search, { SearchText } from '#Search'
import { faFolder, faListCheck, faTurnLeftUp } from '@fortawesome/pro-regular-svg-icons';
import LoadPlanForm from './LoadPlanForm';

const LoadPlan = ({ planPath, handleClose }) => {
	const [ machines, setMachines ] = useState([])
	const [ lastDate, setLastDate ] = useState()
	const api = useContext(APICtx)

	useEffect(() => {
		api.call('machine/list').then(setMachines)
		const today = new Date()
		setLastDate(today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0'))
	}, [])

	return (
		<Dialog
			handleClose={handleClose}
			open={Boolean(planPath)}
			title="Carica programma"
			width="md"
		>
			<LoadPlanForm planPath={planPath} machines={machines} lastDate={lastDate} setLastDate={setLastDate} />
		</Dialog>
	)
}

const Nav = ({ path, setPath }) => {
	const handleChPath = index =>
		setPath(path.slice(0, index + 1))

	return (
		<Breadcrumbs aria-label="breadcrumb">
			<Link key="_home" underline="hover" color="inherit" href="#" onClick={() => handleChPath(-1)}>
				Home
			</Link>

			{ path.map((category, categoryIndex) => {
				if(categoryIndex < path.length - 1)
					return (
						<Link key={categoryIndex} underline="hover" color="inherit" href="#" onClick={() => handleChPath(categoryIndex)}>
							{category}
						</Link>
					)
				else 
					return (
						<Typography key={categoryIndex} color="text.primary">
							{category}
						</Typography>
					)
			})}
		</Breadcrumbs>
	)
}

const TreeItem = ({ tag, itemIcon, actionIcon, actionFn }) => {
	return (
		<ListItem
			// secondaryAction={
			// 	<IconButton edge="end" onClick={() => actionFn()}>
			// 		<FontAwesomeIcon icon={actionIcon} />
			// 	</IconButton>
			// }
			disablePadding
		>
			<ListItemButton onClick={() => actionFn()}>
				<ListItemAvatar>
					<Avatar>
						<FontAwesomeIcon icon={itemIcon} />
					</Avatar>
				</ListItemAvatar>
				<ListItemText
					primary={tag}
				/>
			</ListItemButton>
		</ListItem>
	)
}

export default function ProgramTree() {
	const [ path, setPath ] = useState([])
	const [ categories, setCategories ] = useState([])
	const [ programs, setPrograms ] = useState([])
	const [ loadPlanPath, setLoadPlanPath ] = useState(null)
	const [ searchString, setSearchString ] = useState('')
	const api = useContext(APICtx)

	const handleChPath = (newPath) => {
		setPath(newPath)
		setSearchString('')
	}
	const handleRefresh = () => api.call('program/list', { path, searchString })
		.then(result => {
			setCategories(result?.categories || [])
			setPrograms(result?.programs || [])
		})

	useEffect(() => {
		handleRefresh()
	}, [ path, searchString ])

	return (
		<Tpl title="Macchine industria 4.0">
			<Search>
				<SearchText label="Cerca" name="src-text" onChange={setSearchString} value={searchString} />
			</Search>
			<Nav path={path} setPath={handleChPath} />
			<LoadPlan
				handleClose={() => setLoadPlanPath(null)}
				planPath={loadPlanPath}
			/>
			<List>
				{ Boolean(path?.length) && (
					<TreeItem
						key="_back"
						tag=".."
						itemIcon={faTurnLeftUp}
						// actionIcon={faTurnLeftUp}
						actionFn={() => handleChPath(path.slice(0, -1))}
					/>
				)}
				{ categories?.map(category => (
					<TreeItem
						key={'C-' + category}
						tag={category}
						itemIcon={faFolder}
						// actionIcon={faFolderOpen}
						actionFn={() => handleChPath(path.concat(category))}
					/>
				))}
				{ programs?.map(program => (
					<TreeItem
						key={'P-' + program}
						tag={program}
						itemIcon={faListCheck}
						// actionIcon={faClock}
						actionFn={() => setLoadPlanPath(path.concat(program))}
					/>
				))}
			</List>
		</Tpl>
	)
}
