import React from 'react'
import Form, { Input } from '#Form'
import Dialog from '#Dialog'

const EditForm = ({ _id }) => {
	const formDef = {
		_id,
		callGet: _id && "ops/stock-item/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Codice" name="code" disabled />
			<Input label="Descrizione" name="description" multiline disabled />
		</Form>
	)
}

const StockItemEdit = ({ _id, open, handleClose }) => {
	const title = 'Dettagli articolo'
	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}
export default StockItemEdit
