import React, { useContext } from 'react'
import Form, { Input, Autocomplete } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const EditForm = ({ _id }) => {
	const api = useContext(APICtx)

	const formDef = {
		_id,
		callSet: _id ? "ops/odl/update" : "ops/odl/create",
		callGet: _id && "ops/odl/get",
	}

	const getRecipeOpts = async filterString =>
		api.call('ops/recipe/list-options', { filterString })
	const getStockItems = async filterString =>
		api.call('ops/stock-item/list-options', { filterString })

	return (
		<Form {...formDef}>
			<Input label="Numero" name="code" required />
			{ !_id && <Autocomplete label="Ricetta" name="recipe" getOptions={getRecipeOpts} required /> }
			{ !_id && <Autocomplete label="Articolo" name="stockItem" getOptions={getStockItems} required /> }
		</Form>
	)
}

const ODLEdit = ({ _id, open, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh() : handleRefresh()
	}

	const title = (_id ? 'Modifica' : 'Crea') + ' ODL'
	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}
export default ODLEdit
