import React, { useState, useEffect, useContext, useMemo } from 'react'
import Dialog from '#Dialog'

import APICtx from '#api'
import DataList from '#DataList'
import { reasonsOpts } from './schemas'

const typesOpts = [
	{ value:'timer-started', label:'Timer avviato' },
	{ value:'timer-stopped', label:'Timer fermato' },
	{ value:'phase-closed', label:'Fase chiusa' },
	{ value:'requirements-ok', label:'Requisiti confermati' },
	{ value:'waste-update', label:'Aggiornamento scarti' },
]

const useTableDef = () => ([
	{ label:"Data/ora", field:'eventTS', type:'datetime' },
	{ label:"Utente", field:'_createdBy', type:'user' },
	{ label:"Azione", content:row => {
		let text = typesOpts.find(type => row.type === type.value)?.label || '?'
		if(row.timerType === 'preparation')
			text += ' [PREPARAZIONE]'
		else if(row.type === 'timer-stopped')
			text += ' - ' + (reasonsOpts.find(reason => row.reason === reason.value)?.label || 'ragione sconosciuta')
		return text
	}},
])

const EventsHistory = ({ open, _id:odlPhase, handleClose }) => {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const tableDef = useTableDef()

	useEffect(() => {
		odlPhase && api.call('ops/event/list', { odlPhase }).then(setIds)
	}, [ odlPhase ])

	const getRow = _id => api.call('ops/event/get', { _id })

	return (
		<Dialog
			handleClose={handleClose}
			open={open}
			title={"Riepilogo eventi"}
			width="md"
		>
			<DataList def={tableDef} ids={ids} getRow={getRow} />
		</Dialog>
	)
}
export default EventsHistory
