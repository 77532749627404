import React, { useContext, useEffect, useState } from 'react'
import Form, { InputNumber, Select } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const EditForm = ({ _id, odl }) => {
	const api = useContext(APICtx)
	const [ resourcesOpts, setResourcesOpts ] = useState([])

	useEffect(() => {
		api.auth.groupsAsOptions({ acl:'ops-resource' }).then(setResourcesOpts)
	}, [])

	const formDef = {
		_id,
		callSet: "ops/odl/phase-setup/set",
		callGet: "ops/odl/phase-setup/get",
		extraData: { odl },
	}

	return (
		<Form {...formDef}>
			<Select label="Risorsa" name="resource" required options={resourcesOpts} emptyLabel="-- nessuna risorsa selezionata --" />
			<InputNumber label="Quantità" name="quantity" decimals={0} />
		</Form>
	)
}

const ODLPhaseEdit = ({ _id, odl, open, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh() : handleRefresh()
	}

	const title = 'Configura fase ODL'
	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} odl={odl} />
		</Dialog>
	)
}
export default ODLPhaseEdit
