import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import { faListCheck } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Data", field:"date", type:"date" },
]

export default function DatesList() {
	const [ ids, setIds ] = useState([])
	const { machine } = useParams()
	const api = useContext(APICtx)

	const handleRefresh = () => api.call('plan/date-list', { _id:machine }).then(setIds)

	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = date => ({ date })

	const defBtns = [
		{
			icon: faListCheck,
			label: 'Mostra piano della giornata',
			linkTo: row => '/machine/plans/' + machine + '/' + row.date,
		},
	]

	const title = "Date programmate - " + machine
	return (
		<Tpl title={title}>
			<DataList def={tabDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
		</Tpl>
	)
}
