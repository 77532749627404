import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'

import {
	faBoxes,
	faDiamondExclamation,
	faGears,
	faPause,
	faPlay,
	faStop,
	faTimer,
	faWineGlassCrack,
} from '@fortawesome/pro-regular-svg-icons'

import {
	Resource,
	StockItem,
	Timer,
} from '../Widgets'
import RequirementsConfirm from './RequirementsConfirm'
import WastesUpdate from '../WastesUpdate'
import ODLPauseReason from './PauseReason'
import StockItemDetails from './StockItemDetails'

const colors = {
	new: 'inherit',
	paused: 'rgba(255, 255, 0, 0.8)',
	running: 'rgba(0, 255, 0, 0.8)',
	preparing: 'rgba(0, 255, 255, 0.8)',
}

const tableDef = [
	{ label:"ODL", hide:[ 'xs', 'sm', 'md' ], content:row => row?.odlData?.code },
	{ label:"Articolo", content:row => <StockItem _id={row?.odlData?.stockItem} /> },
	{ label:"Fase", field:'label' },
	{ label:"Quantità", field:'quantity', type:'number' },
	{ label:"Risorsa", content:row => <Resource {...row} /> },
	{ label:"Timer", content:row => <Timer {...row} />},
]

const isPhasePaused = ({ hasRequirements, requirementsOk, timersStarted }) => {
	if(hasRequirements && !requirementsOk)
		return false
	if(timersStarted?.length)
		return false
	return true
}
const isPhaseRunning = ({ timersStarted }, timerType) =>
	Boolean(
		timersStarted
			?.filter(timer => timer.type === timerType)
			?.length
	)
const canIStartTimer = ({ hasRequirements, requirementsOk, timersStarted }, timerType) => {
	if(hasRequirements && !requirementsOk)
		return false
	const blockingEvents = timersStarted
		?.filter(timer => timer.isMine || timer.type !== timerType)
	return !blockingEvents?.length
}
const canIPauseTimer = ({ timersStarted }, timerType) => {
	const pausableEvents = timersStarted
		?.filter(timer => timer.isMine && timer.type === timerType)
	return !!pausableEvents?.length
}

export default function RecipePhaseList() {
	const api = useContext(APICtx)
	const { recipe } = useParams()
	const [ ids, setIds ] = useState([])
	const [ requirementsConfirm, setRequirementsConfirm ] = useState()
	const [ wastesUpdate, setWastesUpdate ] = useState()
	const [ pauseItem, setPauseItem ] = useState()
	const [ stockItem, setStockItem ] = useState()

	const handleStart = ({ _id, handleRowRefresh }) =>
		api.call('ops/job/timer/start', { _id, type:'main' })
			.then(handleRowRefresh)
	const handlePrepStart = ({ _id, handleRowRefresh }) =>
		api.call('ops/job/timer/start', { _id, type:'preparation' })
			.then(handleRowRefresh)
	const handlePrepPause = ({ _id, handleRowRefresh }) =>
		api.call('ops/job/timer/pause', { _id })
			.then(handleRowRefresh)
	const handleFinish = ({ _id, label }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler concludere la fase "' + label + '"?',
			onConfirm: () => api.call('ops/job/timer/finish', { _id })
				.then(handleRefresh),
		})

	const handleRefresh = () =>
		api.call('ops/job/list', { recipe }).then(setIds)

	useEffect(() => {
		handleRefresh()
		const intervalId = setInterval(() => {
			handleRefresh()
		}, 10000)
		return () => clearInterval(intervalId)
	}, [])

	const getRow = _id => api.call('ops/job/get', { _id })
	const defBtns = [
		{
			icon: faTimer,
			label: 'Gestione timer',
			linkTo: row => `/ops/odl/${row.odl}/odl-phases/${row._id}/timers-mgm`,
			show: api.auth.chk('ops-admin'),
		},
		{
			icon: faDiamondExclamation,
			label: 'Conferma requisiti',
			onClick: setRequirementsConfirm,
			show: row => Boolean(row.hasRequirements && !row.requirementsOk),
		},
		{
			icon: faGears,
			label: 'Avvia timer preparazione',
			onClick: handlePrepStart,
			show: row => canIStartTimer(row, 'preparation'),
		},
		{
			icon: faPause,
			label: 'Pausa timer preparazione',
			onClick: handlePrepPause,
			show: row => canIPauseTimer(row, 'preparation'),
		},
		{
			icon: faPlay,
			label: 'Avvia timer',
			onClick: handleStart,
			show: row => canIStartTimer(row, 'main'),
		},
		{
			icon: faPause,
			label: 'Pausa timer',
			onClick: setPauseItem,
			show: row => canIPauseTimer(row, 'main'),
		},
		{
			icon: faStop,
			label: 'Termina fase',
			onClick: handleFinish,
			show: isPhasePaused,
		},
		{
			icon: faWineGlassCrack,
			label: 'Scarti',
			onClick: setWastesUpdate,
			show: row => Boolean(!row.hasRequirements || row.requirementsOk),
		},
		{
			icon: faBoxes,
			label: 'Dettagli articolo',
			onClick: row => setStockItem(row?.odlData?.stockItem),
		},
	]
	const rowProps = row => {
		let timerPhase = 'new'
		if(isPhaseRunning(row, 'main'))
			timerPhase = 'running'
		else if(isPhaseRunning(row, 'preparation'))
			timerPhase = 'preparing'
		else if(row.timerTotal + row.prepTimerTotal > 0)
			timerPhase = 'paused'
		return {
			backgroundColor: colors[timerPhase],
		}
	}

	return (
		<Tpl title="Coda lavori">
			<RequirementsConfirm {...requirementsConfirm} open={Boolean(requirementsConfirm)} handleClose={() => setRequirementsConfirm(null)} />
			<WastesUpdate {...wastesUpdate} open={Boolean(wastesUpdate)} handleClose={() => setWastesUpdate(null)} />
			<ODLPauseReason {...pauseItem} open={Boolean(pauseItem)} handleClose={() => setPauseItem()} />
			<StockItemDetails _id={stockItem} open={Boolean(stockItem)} handleClose={() => setStockItem()} />
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} rowProps={rowProps} autorefresh={5000} />
		</Tpl>
	)
}
