import React from 'react'
import Form, { Input } from '#Form'
import Dialog from '#Dialog'

const EditForm = ({ _id, recipe }) => {

	const formDef = {
		_id,
		callSet: _id ? "ops/phase/update" : "ops/phase/create",
		callGet: _id && "ops/phase/get",
		extraData: { recipe },
	}

	return (
		<Form {...formDef}>
			<Input label="Nome" name="label" />
		</Form>
	)
}

const RecipePhaseEdit = ({ _id, recipe, open, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh() : handleRefresh()
	}

	const title = (_id ? 'Modifica' : 'Crea') + ' fase'
	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} recipe={recipe} />
		</Dialog>
	)
}
export default RecipePhaseEdit
