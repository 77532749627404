import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import { faDown, faTrash, faUp } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Sequenza", field:"seq" },
	{ label:"Programma", field:"program" },
]

export default function DatesList() {
	const [ ids, setIds ] = useState([])
	const { machine, date } = useParams()
	const api = useContext(APICtx)

	const handleDelete = row => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler rimuovere la sequenza "' + row.seq + '" del programma giornaliero?',
			onConfirm: () => api.call('plan/remove', { machine, date, name:row.name }).then(handleRefresh),
		})
	}
	const handleMoveForward = row => api.call('plan/move/forward', { machine, date, name:row.name }).then(handleRefresh)
	const handleMoveBackward = row => api.call('plan/move/backward', { machine, date, name:row.name }).then(handleRefresh)
	const handleRefresh = () => api.call('plan/list', { machine, date }).then(setIds)

	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = name => {
		const seq = name.slice(0, 3)
		const program = name.slice(4)
		return { seq, program, name}
	}

	const defBtns = [
		{
			icon: faUp,
			label: 'Anticipa programma',
			onClick: handleMoveBackward,
		},
		{
			icon: faDown,
			label: 'Rimanda programma',
			onClick: handleMoveForward,
		},
		{
			icon: faTrash,
			label: 'Elimina programma',
			onClick: handleDelete,
		},
	]

	const title = machine + " - " + date
	return (
		<Tpl title={title}>
			<DataList def={tabDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
		</Tpl>
	)
}
