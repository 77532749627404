import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'

import RecipePhaseRequirementEdit from './RecipePhaseRequirementEdit';

import {
	faPlus,
	faEdit,
	faTrash,
} from '@fortawesome/pro-regular-svg-icons'


const tableDef = [
	{ label:"Nome", field:'label' },
]

export default function RecipePhaseWastes() {
	const api = useContext(APICtx)
	const { recipe, phase } = useParams()
	const [ recipeData, setRecipeData ] = useState()
	const [ phaseData, setPhaseData ] = useState()
	const [ ids, setIds ] = useState([])
	const [ editItem, setEditItem ] = useState()

	const handleDelete = ({ _id, label }) =>
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare il requisito "' + label + '"?',
			onConfirm: () => api.call('ops/requirement/delete', { _id })
				.then(handleRefresh),
		})

	const handleRefresh = () =>
		api.call('ops/requirement/list', { phase }).then(setIds)
	useEffect(() => {
		handleRefresh()
		api.call('ops/recipe/get', { _id:recipe }).then(setRecipeData)
		api.call('ops/phase/get', { _id:phase }).then(setPhaseData)
	}, [])

	const handleEditClose = async () =>
		setEditItem(null)

	const getRow = _id => api.call('ops/requirement/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica nome',
			onClick: setEditItem,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	]

	const title =
		(recipeData?.label ? recipeData.label+' - ' : '') +
		(phaseData?.label ? phaseData.label+' - ' : '') +
		'Gestione requisiti'
	return (
		<Tpl title={title} backTo={"/ops/recipes/" + recipe + "/phases"}>
			<RecipePhaseRequirementEdit {...editItem} open={Boolean(editItem)} handleRefresh={handleRefresh} handleClose={handleEditClose} />
			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
			<Fab label="Crea requisito" icon={faPlus} onClick={() => setEditItem({ _id:null, phase })} />
		</Tpl>
	)
}
