import React, { useState } from 'react'
import Form, { Input, Select, InputDateTime } from '#Form'
import Dialog from '#Dialog'

// import APICtx from '#api'
import { reasonsOpts, timerTypes } from './schemas'

const EditForm = ({ _id }) => {
	const [ data, setData ] = useState({})

	const formDef = {
		_id,
		callSet: _id && "ops/timer/update",
		callGet: _id && "ops/timer/get",
		data, setData,
	}

	return (
		<Form {...formDef}>
			<InputDateTime label="Data/ora avvio" name="startedTS" />
			<InputDateTime label="Data/ora fine" name="closedTS" />
			<Select label="Motivazione stop" name="closedReason" options={reasonsOpts} emptyLabel="-- Seleziona una motivazione --" disabled={!data?.closedTS} />
		</Form>
	)
}

const MgmTimersEdit = ({ _id, open, handleClose, handleRefresh, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		_id ? handleRowRefresh() : handleRefresh()
	}

	const title = (_id ? 'Modifica' : 'Crea') + ' timer'
	return _id ? (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	) : null
}
export default MgmTimersEdit
