import React, { useContext, useEffect, useState } from 'react'
import Form, { Checkbox } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const EditForm = ({ _id, phase }) => {
	const api = useContext(APICtx)
	const [ requirements, setRequirements ] = useState([])

	useEffect(() => {
		api.call('ops/requirement/list-options', { phase }).then(setRequirements)
	}, [])

	const formDef = {
		_id,
		callSet: "ops/job/confirm-requirements",
		// callGet: "ops/odl/phase-setup/get",
		// extraData: { odl },
	}

	return (
		<Form {...formDef}>
			{ requirements.map(opt => (
				<Checkbox label={opt.label} name={"requirements."+opt.value} />
			))}
		</Form>
	)
}

const RequirementsConfirm = ({ _id, phase, open, handleClose, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		handleRowRefresh?.()
	}

	const title = 'Conferma requisiti'
	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} phase={phase} />
		</Dialog>
	)
}
export default RequirementsConfirm
