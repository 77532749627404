import React from 'react';
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Select } from '#Form'

// const machineOpts = [
// 	{ value:'btm' },
// 	{ value:'simec' },
// 	{ value:'kasto' },
// ]

export default function MachineEdit({ _id }) {
	// const { _id } = useParams()

	// const title = (_id ? 'Modifica' : 'Crea') + ' macchina'
	// const formDef = {
	// 	_id,
	// 	callSet: _id ? "machine/update" : "machine/create",
	// 	callGet: _id && "machine/get",
	// }
	// return (
	// 	<Tpl title={title} backTo="/machine/list">
	// 		<Form {...formDef}>
	// 			<Input label="Matricola" name="serial" required />
	// 			<Input label="Nome" name="label" required />
	// 			<Input label="Indirizzo IP" name="ipAddress" />
	// 			{/* <Select label="Tipologia" name="machineType" options={machineOpts} emptyLabel="-- nessuna tipologia selezionata --" /> */}
	// 		</Form>
	// 	</Tpl>
	// )

	const formDef = {
		_id,
		callSet: _id ? "machine/update" : "machine/create",
		callGet: _id && "machine/get",
	}

	return (
		<Form {...formDef}>
			<Input label="Nome" name="label" required />
			{/* <Input label="Descrizione" name="description" required />
			<InputDate label="Scadenza compilazione form" name="formsExpiry" required />
			<Select label="Tipologia" name="type" options={eventTypes} emptyLabel="--- seleziona una tipologia ---" required /> */}
		</Form>
	)
}
