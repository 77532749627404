import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
// import Fab from '#Fab'

import MgmTimersEdit from './MgmTimersEdit'
import { reasonsOpts, timerTypes } from './schemas'

import {
	faEdit,
} from '@fortawesome/pro-regular-svg-icons'


const useTableDef = () => ([
	{ label:"Tipologia", content:row => row.timerType === 'preparation' ? 'Preparazione' : 'Lavorazione' },
	{ label:"Utente", field:'startedBy', type:'user' },
	{ label:"Attivato", field:'startedTS', type:'datetime' },
	{ label:"Fermato", field:'closedTS', type:'datetime' },
	{ label:"Durata", content:row => {
		if(!row.startedTS || !row.closedTS)
			return null
		return row.closedTS - row.startedTS
	}, type:'datetime-interval'},
	{ label:"Motivazione stop", content:row => {
		if(!row.closedTS)
			return '(in corso)'
		if(row.timerType === 'preparation')
			return '-'
		return (reasonsOpts.find(reason => row.closedReason === reason.value)?.label || 'ragione sconosciuta')
	}},
])

export default function MgmTimers() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ editItem, setEditItem ] = useState()
	const { odlPhase } = useParams()
	const tableDef = useTableDef()

	// const handleDelete = ({ _id, label }) =>
	// 	api.dialogConfirm({
	// 		text: 'Sei sicuro di voler eliminare la sequenza "' + label + '"?',
	// 		onConfirm: () => api.call('ops/recipe/delete', { _id })
	// 			.then(handleRefresh),
	// 	})

	const handleRefresh = () => odlPhase && api.call('ops/timer/list', { odlPhase }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [])

	// const handleEditClose = async () =>
	// 	setEditItem(null)

	const getRow = async _id => api.call('ops/timer/get', { _id })
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica timer',
			onClick: setEditItem,
		},
	]

	return (
		<Tpl title="Timer">
			<MgmTimersEdit
				{...editItem}
				open={Boolean(editItem)}
				handleRefresh={handleRefresh}
				handleClose={() => setEditItem(null)}
			/>

			<DataList def={tableDef} rowActions={defBtns} ids={ids} getRow={getRow} pageSize={30} />
			{/* <Fab label="Crea sequenza" icon={faPlus} onClick={() => setEditItem({ _id:null })} /> */}
		</Tpl>
	)
}
