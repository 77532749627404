import React, { useContext, useEffect, useState } from 'react'
import Form, { Input, InputNumber } from '#Form'
import Dialog from '#Dialog'

import APICtx from '#api'

const EditForm = ({ _id, phase }) => {
	const api = useContext(APICtx)
	const [ wastes, setWastes ] = useState([])

	useEffect(() => {
		api.call('ops/waste/list-options', { phase }).then(setWastes)
	}, [])

	const formDef = {
		_id,
		callSet: "ops/phase-waste/update",
		callGet: "ops/phase-waste/get",
	}

	return (
		<Form {...formDef}>
			{ wastes.map(opt => (
				<InputNumber label={opt.label} name={"wastes."+opt.value} decimals={0} />
			))}
			<Input label="Altro" name="other" multiline />
		</Form>
	)
}

const WastesUpdate = ({ _id, phase, open, handleClose, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		handleRowRefresh?.()
	}

	const title = 'Scarti'
	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} phase={phase} />
		</Dialog>
	)
}
export default WastesUpdate
