import React from 'react'
import Form, { Radio } from '#Form'
import Dialog from '#Dialog'
import { reasonsOpts } from '../schemas'

const EditForm = ({ _id }) => {
	const formDef = {
		_id,
		callSet: "ops/job/timer/pause",
	}

	return (
		<Form {...formDef}>
			<Radio label="Motivazione" name="reason" options={reasonsOpts} />
		</Form>
	)
}

const PauseReason = ({ _id, open, handleClose, handleRowRefresh }) => {
	const triggerClose = () => {
		handleClose()
		handleRowRefresh?.()
	}

	const title = 'Motivazione pausa'
	return (
		<Dialog
			handleClose={triggerClose}
			open={open}
			title={title}
			width="md"
		>
			<EditForm _id={_id} />
		</Dialog>
	)
}
export default PauseReason
